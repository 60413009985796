import { render, staticRenderFns } from "./ConteudoForm.vue?vue&type=template&id=1b1202a0&"
import script from "./ConteudoForm.js?vue&type=script&lang=js&"
export * from "./ConteudoForm.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QEditor from 'quasar/src/components/editor/QEditor.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCircularProgress from 'quasar/src/components/circular-progress/QCircularProgress.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QIcon,QInput,QToggle,QBtnToggle,QSelect,QEditor,QBtn,QCircularProgress});
