import PadraoModel from 'src/core/model/PadraoModel.js'

export default class NotificacaoModel extends PadraoModel {
  recurso = 'notificacoes'
  microServico = 'usuario'
  include = null
  searchFixo = null
  nome = 'Notificações'

  colunasGrid = []

  form = {
    id: null,
    usuario_id: null,
    tipo: null,
    titulo: null,
    para: null,
    parametros: null,
    data_enviar: null,
    conteudo_chave_interna: null,
    situacao_auxiliar: null,
    id_auxiliar: null,
    operadora_auxiliar: null,

    palavras: []
  }

  constructor () {
    super()
    this.formLimpo = Object.assign({}, this.form)
  }
}
