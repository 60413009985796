import DpFormMixin from 'src/core/components/DpForm/DpFormMixin.js'
import DpForm from 'src/core/components/DpForm/DpForm2.vue'
import ConteudoModel from 'src/model/usuario/ConteudoModel.js'
import NotificacaoModel from 'src/model/usuario/NotificacaoModel.js'
import DpGridStore from 'src/store/dp-grid/'
import vTopFilters from 'src/core/filters/vTopFilters'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import { LocalStorage, clone } from 'quasar'

export default {
  name: 'ConteudoForm',
  components: { DpForm },
  mixins: [DpFormMixin, NotificacaoMixin, vTopFilters],
  data () {
    return {
      modelPadrao: new ConteudoModel(),
      modelNotificacao: new NotificacaoModel(),

      logado: LocalStorage.getItem('logado'),

      desativarCampos: 0,
      perguntaPrincipal: false,
      enviarTeste: false,
      enviandoNoficacao: false,

      optionsTipos: [
        { label: 'Push', value: 'Push', icon: 'fas fa-mobile' },
        { label: 'E-mail', value: 'E-mail', icon: 'fas fa-envelope' },
        { label: 'SMS', value: 'SMS', icon: 'fas fa-comment' },
        { label: 'Conteúdo Site', value: 'Conteúdo Site', icon: 'far fa-edit' },
        { label: 'Ajuda App', value: 'Ajuda App', icon: 'fas fa-question' },
        { label: 'Regulamento', value: 'Regulamento', icon: 'far fa-file' }
      ]
    }
  },
  watch: {
    perguntaPrincipal (value) {
      if (value) {
        this.modelPadrao = new ConteudoModel()
        this.modelPadrao.get(`search=parent_id:null;tipo:${this.modelPadrao.form.tipo}&searchJoin=and`)
      }
    }
  },
  beforeCreate () {
    if (!this.$store.hasModule('dp-grid')) this.$store.registerModule('dp-grid', DpGridStore)
  },
  methods: {
    atualizarChave () {
      this.modelPadrao.form.chave_interna = this.modelPadrao.form.chave_interna.split(' ').join('-').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').trim()
    },

    verificarAtivoPergunta () {
      if (this.modelPadrao.form.tipo === 'Ajuda App' || this.modelPadrao.form.tipo === 'Conteúdo Site' || this.modelPadrao.form.tipo === 'Regulamento' || this.modelPadrao.form.tipo === 'Condição de Pagamento') {
        if (this.modelPadrao.form.parent_id) this.perguntaPrincipal = true
      }
    },

    parametrosAceitos () {
      var p = this.modelPadrao.form.parametros_aceitos.split(',')
      var newArray = []
      p.map(e => newArray.push({ palavra: e, trocar: '' }))
      this.modelNotificacao.form.palavras = newArray
    },

    pushParametrosAceitos (palavra, trocar) {
      const encontrado = this.modelNotificacao.form.palavras.find(ele => ele.palavra === palavra)
      if (!encontrado) this.modelNotificacao.form.palavras.push({ palavra: palavra, trocar: trocar, oculto: true })
    },

    atualizarUrl () {
      this.modelPadrao.form.url = this.modelPadrao.form.url.split(' ').join('-').toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').trim()
    },

    onSubmit (enviarTeste = false) {
      this.$refs.formPadrao.situacao = 'salvando'
      const aviso = this.notificacao('enviando')

      const send = clone(this.modelPadrao.form)

      this.modelPadrao.salvar(send).then((res) => {
        // atualizar rapido no grid
        const novoGrid = clone(this.$store.getters['dp-grid/getDados'][this.modelPadrao.recurso])
        if (novoGrid !== undefined) {
          const filtrado = novoGrid.map((item) => {
            if (item.id === this.modelPadrao.form.id) { return this.modelPadrao.form } else { return item }
          })
          this.$store.commit('dp-grid/putDados', { recurso: this.modelPadrao.recurso, payload: filtrado })
        }

        if (enviarTeste === true) {
          this.onSubmitEnviarTeste(aviso)
        } else {
          aviso()
          this.$refs.formPadrao.situacao = 'salvo'
          this.$refs.formPadrao.irParaListaInterno()
          this.$refs.formPadrao.resetarForm()
        }
      }).catch(error => {
        aviso()
        this.notificacao('erro', error.msg)
        console.error(error)
      })
    },

    onSubmitEnviarTeste (aviso) {
      this.enviandoNoficacao = true

      const send = clone(this.modelNotificacao.form)

      send.usuario_id = this.logado.id
      send.data_enviar = this.$options.filters.hojeEngDateTime()
      send.conteudo_chave_interna = this.modelPadrao.form.chave_interna
      send.palavras = send.palavras.filter((e) => {
        e.palavra = `{{${e.palavra.trim()}}}`
        if (e.trocar && e.trocar.length > 0) return e
      })

      console.log('onSubmitEnviarTeste: ', send)

      this.modelNotificacao.salvar(send).then((res) => {
        this.enviandoNoficacao = false
        this.$refs.formPadrao.situacao = 'salvo'
        this.notificacao('salvo', 'Salvo com sucesso', 3000)
        aviso()
      }).catch(error => {
        this.enviandoNoficacao = false
        this.$refs.formPadrao.situacao = 'salvo'
        aviso()
        this.notificacao('erro', error.msg)
        console.error(error)
      })
    },

    toolbarEditor (q) {
      return [
        [
          {
            label: q.lang.editor.align,
            icon: q.iconSet.editor.align,
            fixedLabel: true,
            options: ['left', 'center', 'right', 'justify']
          }
        ],
        ['bold', 'italic', 'strike', 'underline', 'subscript', 'superscript'],
        ['token', 'hr', 'link', 'custom_btn'],
        ['fullscreen'],
        [
          {
            label: q.lang.editor.formatting,
            icon: q.iconSet.editor.formatting,
            list: 'no-icons',
            options: [
              'p',
              'h1',
              'h2',
              'h3',
              'h4',
              'h5',
              'h6',
              'code'
            ]
          },
          {
            icon: q.iconSet.editor.fontSize,
            fixedLabel: true,
            fixedIcon: true,
            list: 'no-icons',
            options: [
              'size-1',
              'size-2',
              'size-3',
              'size-4',
              'size-5',
              'size-6',
              'size-7'
            ]
          },
          {
            icon: q.iconSet.editor.font,
            fixedLabel: false,
            fixedIcon: true,
            list: 'no-icons',
            options: [
              'default_font',
              'arial',
              'arial_black',
              'comic_sans',
              'courier_new',
              'impact',
              'lucida_grande',
              'times_new_roman',
              'verdana'
            ]
          },
          'removeFormat'
        ],
        ['unordered', 'ordered', 'outdent', 'indent'],

        ['undo', 'redo'],
        ['viewsource']
      ]
    },

    fontsEditor () {
      return {
        arial: 'Arial',
        arial_black: 'Arial Black',
        comic_sans: 'Comic Sans MS',
        courier_new: 'Courier New',
        impact: 'Impact',
        lucida_grande: 'Lucida Grande',
        times_new_roman: 'Times New Roman',
        verdana: 'Verdana'
      }
    }
  }
}
